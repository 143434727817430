// import GoogleMapMarker from "components/GoogleMapMarker/GoogleMapMarker";
import React from "react";
import { useSelector } from "react-redux";
import { generateWebsiteUrl } from "../../../utils/generateWebsiteUrl";

const CompanyAbout = ({ company }) => {
  const language = useSelector((state) => state.language);

  const renderCompayLinks = (socialLink) => {
    if (!socialLink?.url) {
      return null;
    }
    switch (socialLink?.media) {
      case "facebook":
        return (
          <a href={socialLink?.url} target="_blank" rel="noopener noreferrer">
            <div className="h-6 w-6 object-contain mr-3">
              <img
                objectFit="contain"
                src="/facebook.png"
                alt="Facebook"
                height={25}
                width={25}
              />
            </div>
          </a>
        );
      case "instagram":
        return (
          <a href={socialLink?.url} target="_blank" rel="noopener noreferrer">
            <div className="h-6 w-6 object-contain mr-3">
              <img
                objectFit="contain"
                src="/instagram.png"
                alt="Instagram"
                height={25}
                width={25}
              />
            </div>
          </a>
        );
      case "twitter":
        return (
          <a href={socialLink?.url} target="_blank" rel="noopener noreferrer">
            <div className="h-6 w-6 object-contain mr-3">
              <img
                objectFit="contain"
                src="/twitter.svg"
                alt="Twitter"
                height={25}
                width={25}
              />
            </div>
          </a>
        );
      case "youtube":
        return (
          <a href={socialLink?.url} target="_blank" rel="noopener noreferrer">
            <div className="h-6 w-6 object-contain mr-3">
              <img
                objectFit="contain"
                src="/youtube.png"
                alt="Youtube"
                height={25}
                width={25}
              />
            </div>
          </a>
        );
      case "tiktok":
        return (
          <a href={socialLink?.url} target="_blank" rel="noopener noreferrer">
            <div className="h-6 w-6 object-contain mr-3">
              <img
                objectFit="contain"
                src="/tiktok.svg"
                alt="Tiktok"
                height={25}
                width={25}
              />
            </div>
          </a>
        );
      default:
        return null;
    }
  };

  return (
    <div className="w-full md:w-4/12 md:ml-6 text-black md:mt-0 mt-12">
      <div className="border border-gray-200 rounded-lg bg-white mb-4">
        <div className="p-5 border-b font-bold  text-lg border-gray-200">
          {language["ABOUT"]}
        </div>
        <div className="p-5">
          <div className=" mb-4 text-sm block">
            <div className="font-bold">{language["WEBSITE"]}:</div>
            <a
              href={generateWebsiteUrl(company?.company?.websiteURL)}
              target="_blank"
              rel="noopener noreferrer"
            >
              {company?.company?.websiteURL}
            </a>
          </div>
          {company?.company?.phoneNumber && (
            <div className=" mb-4 text-sm block">
              <div className="font-bold">{language["PHONE"]}:</div>
              <a href={`tel: ${company?.company?.phoneNumber}`}>{company?.company?.phoneNumber}</a>
            </div>
          )}
          {company?.company?.email && (
            <div className=" mb-4 text-sm block">
              <div className="font-bold">{language["EMAIL"]}:</div>
              <a href={`mailto: ${company?.company?.email}`}>{company?.company?.email}</a>
            </div>
          )}
          {company?.company?.socialLinks?.length > 0 && (
            <div className=" mb-4 text-sm block">
              <div className="font-bold">{language["LINKS"]}:</div>
              <div className="flex items-center flex-wrap mt-2">
                {company?.company?.socialLinks?.length > 0 &&
                  company?.company?.socialLinks.map((item, index) => (
                    <div key={index}>{renderCompayLinks(item)}</div>
                  ))}
              </div>
            </div>
          )}
        </div>
      </div>

      {company?.company?.address && (
        <div className="border border-gray-200 rounded-lg bg-white mb-4">
          <div className="p-5 border-b font-bold  text-lg border-gray-200">
            {language["LOCATION"]}
          </div>
          <div className="p-5 text-sm capitalize">{company?.company?.address}</div>
          <div className="p-1">
            {/* {company?.address?.lng && company?.address?.lat && (
              <GoogleMapMarker
                height={350}
                long={company?.address?.lng}
                lat={company?.address?.lat}
              />
            )} */}
          </div>
        </div>
      )}

      <div className="border border-gray-200 rounded-lg bg-white mb-4">
        <div className="p-5 border-b font-bold  text-lg border-gray-200">
          Trustbucket
        </div>
        <div className="p-5">
          <div className="text-sm mb-5">{language["ABOUT_INFO"]}</div>
          <a
            href="https://admin.trustbucket.io"
            className="btn bg-blue-600 text-white transition-colors hover:bg-blue-700"
          >
            {language["CREATE_ACC"]}
          </a>
        </div>
      </div>
    </div>
  );
};

export default CompanyAbout;
