export const typeObject = {
  google: {
    image: "/google.svg",
    miniImage: "/google.svg",
    title: "Google",
  },
  recose: {
    image: "/reco.svg",
    miniImage: "/reco.svg",
    title: "Reco.se",
  },
  fresha: {
    image: "/fresha.png",
    miniImage: "/fresha-mini.png",
    title: "Fresha",
  },
  facebook: {
    image: "/facebook.png",
    miniImage: "/facebook.png",
    title: "Facebook",
  },
  bokadirekt: {
    image: "/bokadirekt.png",
    miniImage: "/bokadirekt-mini.png",
    title: "Bokadirekt",
  },
  booking: {
    image: "/booking.svg",
    miniImage: "/booking.svg",
    title: "Booking",
  },
  trustpilot: {
    image: "/trustpilot.svg",
    miniImage: "/trustpilot.svg",
    title: "Trustpilot",
  },
  trustbucket: {
    image: "/mini-logo.svg",
    miniImage: "/mini-logo.svg",
    title: "Trustbucket",
  },
  airbnb: {
    image: "/airbnb.png",
    miniImage: "/airbnb.png",
    title: "Airbnb",
  },
  tripadvisor: {
    image: "/tripadvisor.svg",
    miniImage: "/tripadvisor.svg",
    title: "Tripadvisor",
  },
  hitta: {
    image: "/hitta.svg",
    miniImage: "/hitta.svg",
    title: "Hitta",
  },
  yelp: {
    image: "/yelp-mini.png",
    miniImage: "/yelp-mini.png",
    title: "Yelp",
  },
};
