import * as actionTypes from "../actions/actionTypes";
import en from "../../languages/en.json";

const initialState = en;

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_LANGUAGE:
      return action.payload;
    default:
      return state;
  }
};

export default reducer;
