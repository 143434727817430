import dayjs from "dayjs";
import parse from "html-react-parser";
import { generateWebsiteUrl } from "./generateWebsiteUrl";

export const generateReviewsSchema = (
  slug,
  company,
  reviews
) => {
  return {
    __html: `{
        "@context": "http://schema.org",
        "@type":  "Organization",
        "@id":  "https://reviews.trustbucket.io/${slug}",
        "name": "${company?.name}",
        "logo": "${company?.image}",
        "url":  "${generateWebsiteUrl(company?.websiteURL)}",
        "description":  "${company?.address?.name}",
        "aggregateRating": {
            "@type":"AggregateRating",
            "worstRating":1,
            "bestRating":5,
            "ratingValue":${company?.ratings[0]?.rating},
            "ratingCount":${company?.ratings[0]?.ratingCount}
        },
        "review": [
          ${reviews?.map(
            (item) =>
              `
            {
              "@type": "Review",
              "datePublished":"${
                item?.date && dayjs(item?.date).format("YYYY-MM-DD")
              }",
              "reviewBody":"${parse(item?.description || "")}",
              "itemReviewed":{
                "@type":"Organization",
                "name":"${company?.name}"
             },
              "reviewRating": {
                "@type": "Rating",
                "ratingValue": "${item.rating}"
              },
              "author": {
                "@type": "Person",
                "name": "${item.name}"
              }
            }
            `
          )}
        ]
      }`,
  };
};
