export const START_LOADING = "START_LOADING";
export const FINISH_LOADING = "FINISH_LOADING";

export const SUCCESS_NOTIFICATION = "SUCCESS_NOTIFICATION";
export const ERROR_NOTIFICATION = "ERROR_NOTIFICATION";

export const SET_RATINGS = "SET_RATINGS";
export const SET_RATINGS_TOTAL = "SET_RATINGS_TOTAL";
export const SET_RATINGS_LAST_PAGE = "SET_RATINGS_LAST_PAGE";
export const SET_RATINGS_STARS = "SET_RATINGS_STARS";

export const SET_COMPANY = "SET_COMPANY";

export const SET_LANGUAGE = "SET_LANGUAGE";
