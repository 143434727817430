import axios from "../../config/axios";
import * as actionTypes from "./actionTypes";
import { finishLoading, startLoading } from "./loader";
import { errorsNotification, successNotification } from "./notification";

export const setCompany = (payload) => {
  return {
    type: actionTypes.SET_COMPANY,
    payload,
  };
};

export const singleCompany = (id, callback) => {
  return (dispatch) => {
    dispatch(startLoading());
    axios
      .post(`/business/select`, { id })
      .then(({ data }) => {
        dispatch(successNotification(data.msg));
        dispatch(setCompany(data.business));
        dispatch(finishLoading());
        callback && callback();
      })
      .catch((error) => {
        dispatch(errorsNotification(error?.response?.data?.msg));
        dispatch(finishLoading());
      });
  };
};
