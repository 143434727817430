import React from "react";

const TrustPilotStars = ({ rating }) => {
  const displayStars = () => {
    switch (rating) {
      case 1:
        return "/trustpilot-rate-1.svg";
      case 2:
        return "/trustpilot-rate-2.svg";
      case 3:
        return "/trustpilot-rate-3.svg";
      case 4:
        return "/trustpilot-rate-4.svg";
      case 5:
        return "/trustpilot-rate-5.svg";
      default:
        return "";
    }
  };

  return (
    <div>
      <img className="w-36" src={displayStars()} alt="rating" width={144} height={27} />
    </div>
  );
};

export default TrustPilotStars;
