import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SingleReview from "./SingleReview";
import MiniLoader from "../../misc/MiniLoader";
import { typeObject } from "../../../utils/getObjectByType";
import ProgressBar from "../../misc/ProgressBar";
import { filterRatings } from "../../../store/actions/ratings";

const initialQueryParams = {
  pageNumber: 1,
  pageSize: 10,
  sortField: "date",
  sortOrder: "desc",
};

const CompanyReviews = ({ company, slug, ratings }) => {
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.loader);
  const language = useSelector((state) => state.language);

  const lastPage = useSelector((state) => state.ratings.lastPage);
  const ratingAll = useSelector((state) => state.ratings.ratings);

  const [loading, setLoading] = useState(false);
  const [activeRatings, setActiveRatings] = useState(null);
  const [queryParams, setQueryParams] = useState(initialQueryParams);
  const [type, setType] = useState(null);

  const ratingStars = {};
  ratingAll.forEach((el) => {
    if (ratingStars[el.rating]) {
      ratingStars[el.rating]++;
    } else {
      ratingStars[el.rating] = 1;
    }
  });

  const toggleRatingsStars = (num) => {
    if (activeRatings) {
      if (activeRatings.find((el) => el === num)) {
        if (activeRatings.length === 1) {
          setActiveRatings(null);
          return;
        }
        let newArr = activeRatings.filter((el) => el !== num);
        setActiveRatings(newArr);
      } else {
        setActiveRatings((p) => [...p, num]);
      }
    } else {
      setActiveRatings([num]);
    }
    setQueryParams(initialQueryParams);
  };

  const changeType = (newType) => {
    if (type) {
      if (type.some((el) => el === newType)) {
        let filteredTypes = type.filter((el) => el !== newType);
        if (filteredTypes.length === 0) {
          setType(null);
          return;
        }
        setType(filteredTypes);
      } else {
        setType((p) => [...p, newType]);
      }
    } else {
      setType([newType]);
    }
    setQueryParams((p) => ({ ...p, pageNumber: 1 }));
  };

  useEffect(() => {
    if (slug && activeRatings) {
      dispatch(filterRatings(slug, activeRatings));
    }
  }, [slug, activeRatings]);

  return (
    <>
      <div
        className={
          "border border-gray-200 rounded-lg bg-white p-5 mb-4" +
          (isLoading ? " animate-pulse" : "")
        }
      >
        <div className="font-bold text-lg mb-4 capitalize">
          {language["REVIEWS"]} ({ratings?.total})
        </div>
        <div className="flex sm:flex-row flex-col justify-between">
          <div className="lg:w-1/2 sm:w-9/12 w-full pr-4">
            <div className="font-semibold mb-3">{language["SORT_RATING"]}</div>
            <div className="flex flex-col-reverse">
              {ratingStars &&
                Object.entries(ratingStars).map(([key, value]) => (
                  <div
                    key={key}
                    className="flex items-center cursor-pointer"
                    onClick={() => toggleRatingsStars(parseInt(key))}
                  >
                    <div
                      key={key}
                      className="flex items-center mb-2 w-28 mr-2 cursor-pointer"
                    >
                      <div className="mr-2">
                        {activeRatings?.some((el) => el === key) ? (
                          <img
                            src="/checkbox-blue.svg"
                            alt="checkbox"
                            width={18}
                            height={18}
                          />
                        ) : (
                          <svg
                            width="18"
                            height="18"
                            viewBox="0 0 18 18"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <rect
                              x="1"
                              y="1"
                              width="16"
                              height="16"
                              rx="3"
                              fill="white"
                              stroke="#CBD5E1"
                              strokeWidth="2"
                            />
                          </svg>
                        )}
                      </div>
                      {key}{" "}
                      {parseInt(key) > 1
                        ? `(${language["STARS"]})`
                        : `(${language["STAR"]})`}
                    </div>
                    <ProgressBar
                      percent={
                        (value / ratings?.total) * 100 <= 100
                          ? (value / ratings?.total) * 100
                          : 100
                      }
                    />
                    <div className="ml-2">{value} pcs</div>
                  </div>
                ))}
            </div>
          </div>
          {/* <div className="lg:w-1/2 sm:w-3/12 w-full sm:mt-0 mt-4 pr-4">
            <div className="font-semibold mb-3">
              {language["SELECT_SOURCE"]}
            </div>
            <div className="block">
              {ratings?.ratings?.map((item, index) => (
                <div className="py-1" key={index}>
                  <div
                    className="flex items-center cursor-pointer"
                    onClick={() => changeType(item.type)}
                  >
                    {type && type.some((el) => el === item.type) ? (
                      <img
                        src="/checkbox-blue.svg"
                        alt="checkbox"
                        width={20}
                        height={20}
                      />
                    ) : (
                      <svg
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          x="1"
                          y="1"
                          width="16"
                          height="16"
                          rx="3"
                          fill="white"
                          stroke="#CBD5E1"
                          strokeWidth="2"
                        />
                      </svg>
                    )}
                    <span className="text-sm font-medium ml-2 flex items-center">
                      <div className="h-5 w-5 mr-2">
                        <img
                          src={typeObject[item.type]?.miniImage}
                          alt="google"
                          width={20}
                          height={20}
                        />{" "}
                      </div>
                      {typeObject[item.type]?.title}
                    </span>
                  </div>
                </div>
              ))}
            </div>
          </div> */}
        </div>
      </div>

      {ratings?.ratings?.length > 0 &&
        ratings?.ratings?.map((item, index) => (
          <SingleReview data={item} key={index} company={company} />
        ))}
      {!loading &&
        ratings?.ratings?.length > 0 &&
        queryParams.pageNumber < lastPage && (
          <button
            onClick={() =>
              setQueryParams((p) => ({
                ...p,
                pageNumber: p.pageNumber + 1,
              }))
            }
            className={
              "mt-4 rounded-full shadow-lg border border-solid py-2 px-4 border-gray-200 transition-all hover:bg-blue-600 hover:text-white transition-alltext-gray-400 bg-white"
            }
          >
            <span className="font-medium">{language["LOAD_MORE"]}</span>
          </button>
        )}
      {loading && <MiniLoader />}
    </>
  );
};

export default CompanyReviews;
