import React from "react";
import { generateWebsiteUrl } from "../../../utils/generateWebsiteUrl";
import { useSelector } from "react-redux";

export default function WriteReviewHeader({ company, slug }) {
  const language = useSelector((state) => state.language);

  return (
    <div className={"bg-white py-6 text-black"}>
      <div className="max-w-4xl mx-auto px-4 sm:px-6 flex">
        <div className="flex md:flex-row flex-col justify-between items-center w-full">
          <div className="w-full md:w-8/12 flex items-start sm:items-center">
            <div className="h-24 w-24 object-contain mr-4 rounded-lg sm:mr-7">
              <img
                src={company?.company?.image || "/default-placeholder.png"}
                alt="avatar"
                width={96}
                height={96}
              />
            </div>
            <div>
              <div className="font-bold text-2xl mb-1">{company?.company?.businessName}</div>

              <div className="flex items-center mb-1">
                <div className="font-bold mr-2">
                  <a
                    href={generateWebsiteUrl(company?.company?.websiteURL)}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-blue-600"
                  >
                    {company?.company?.websiteURL}
                  </a>
                </div>
              </div>
              <a className="text-gray-500 font-bold" href={`/${slug}`}>
                {language["BACK"]}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
