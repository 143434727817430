import React from "react";
import { generateWebsiteUrl } from "../../../utils/generateWebsiteUrl";
import RatingStars from "./components/RatingsStars";
import { useSelector } from "react-redux";
// import { url } from "inspector";

const CompanyHeader = ({ company, ratings }) => {
  const language = useSelector((state) => state.language);

  return (
    <div className={"bg-white py-8 text-black"}>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 flex">
        <div className="flex md:flex-row flex-col justify-between items-center w-full">
          <div className="w-full md:w-8/12 flex items-start sm:items-center">
            <div
              className="sm:w-32 sm:h-32 h-24 w-24 object-contain rounded-lg mr-4 sm:mr-7 overflow-hidden"
              style={{
                background: `url('${
                  company?.company?.image ?? "https://reviews.trustbucket.io/default-placeholder.png"
                }') no-repeat center center / cover`,
              }}
            >
              {/* <Image
                src={company?.image ?? "/default-placeholder.png"}
                alt={company?.name}
                width={128}
                height={128}
              /> */}
            </div>
            <div>
              {company?.company?.businessName ? (
                <div className="font-bold sm:text-3xl text-lg mb-2 ">
                  {company?.company?.businessName}
                </div>
              ) : (
                <div className="h-10"></div>
              )}

              <div className="flex items-center mb-2">
                <div className="text-xl sm:text-2xl font-bold mr-2">
                  {ratings?.lastPage?.averageRating
                    ? ratings?.lastPage?.averageRating
                    : "N/A"}
                </div>
                <RatingStars rating={ratings?.lastPage?.averageRating} />
              </div>
              <div className="text-sm sm:text-base">
                {language["BASED_ON"]} {ratings?.total}{" "}
                {language["REVIEWS"]}
              </div>
            </div>
          </div>
          <div className="w-full md:w-4/12 mt-4 md:mt-4 md:ml-4">
            <div className="border border-gray-200 text-sm rounded-lg">
              <div className="p-5 pb-2 mb-3 border-b border-gray-200">
                <a
                  href={generateWebsiteUrl(company?.company?.websiteURL)}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="mb-1 block underline"
                >
                  {company?.company?.websiteURL}
                </a>
                <div className="font-semibold flex items-center text-base">
                  {language["IS"]}{" "}
                  <div className="h-5 mx-1.5">
                    <img
                      src="/trustbucket-verified.svg"
                      alt="verified"
                      height={20}
                      width={20}
                    />{" "}
                  </div>
                  {language["VERIFIED_BY"]}
                </div>
              </div>
              <div className="p-5 pt-0">
                <div className="text-sm">{language["WE_GUARANTEE"]}</div>
                <div className="text-sm">
                  <a href="https://trustbucket.io" className=" underline">
                    {language["WHAT_IS_TB"]}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompanyHeader;
