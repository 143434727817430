import React from "react";

const MiniLoader = () => {
  return (
    <div className="mini-loader">
      <div className="mini-loader__icons-wrapper">
        <div className="lds-ring">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
  );
};

export default MiniLoader;
