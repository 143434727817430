import * as actionTypes from "./actionTypes";
import en from "../../languages/en.json";
import se from "../../languages/se.json";
import nl from "../../languages/nl.json";
import fr from "../../languages/fr.json";
import de from "../../languages/de.json";

export const setLang = (language) => {
  let payload = {};

  if (language === "se") {
    payload = se;
  } else if (language === "nl") {
    payload = nl;
  } else if (language === "fr") {
    payload = fr;
  } else if (language === "de") {
    payload = de;
  } else payload = en;

  return {
    type: actionTypes.SET_LANGUAGE,
    payload,
  };
};
