import React from "react";

const Footer = () => {
  return (
    <footer className="bg-gray-900" aria-labelledby="footer-heading">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex py-4 items-center  justify-between">
          <p className="text-base text-gray-400 md:mt-0 md:order-1">
            &copy; {new Date().getFullYear()} Trustbucket AB, All rights
            reserved.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
