import React from "react";
import "./styles/globals.scss";
import "react-toastify/dist/ReactToastify.css";
// import Layout from "./components/providers/Layout";
import { Provider } from "react-redux";
import Loader from "./components/layout/Loader/Loader";
import Notification from "./components/providers/Notification";
import { BrowserRouter, Route, Router, Routes } from "react-router-dom";
import Company from "./pages/Company";
import CreateReview from "./pages/write-review/CreateReview";
import store from "./store";

function App() {
  const [loading, setLoading] = React.useState(false);
  React.useEffect(() => {
    const start = () => {
      setLoading(true);
    };
    const end = () => {
      setLoading(false);
    };
    Router.events?.on("routeChangeStart", start);
    Router.events?.on("routeChangeComplete", end);
    Router.events?.on("routeChangeError", end);
    return () => {
      Router.events?.off("routeChangeStart", start);
      Router.events?.off("routeChangeComplete", end);
      Router.events?.off("routeChangeError", end);
    };
  }, []);

  return (
    <>
      <header>
        <title>Trustbucket | Ratings</title>
        <link rel="shortcut icon" href="/favicon.svg" />
      </header>

      <Provider store={store}>
        {loading && <Loader />}
        <Notification />
        <BrowserRouter>
          <Routes>
            <Route exact path="/:slug" element={<Company />} />
            <Route
              exact
              path="/write-review/:slug"
              element={<CreateReview />}
            />
            {/* <Redirect from="/*" to="/home" /> */}
          </Routes>
        </BrowserRouter>
      </Provider>
    </>
  );
}

export default App;
