import React from "react";
import Footer from "../../components/layout/Footer/Footer";
import Header from "../../components/layout/Header/Header";
import WriteReviewStars from "../../components/pages/SingleCompany/WriteReviewStars";
import WriteReviewHeader from "../../components/pages/WriteReview/WriteReviewHeader";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import clsx from "clsx";
import { useFormik } from "formik";
import * as Yup from "yup";
import { writeReview } from "../../store/actions/ratings";
import EmailThank from "../../components/pages/WriteReview/EmailThank";
import { setLang } from "../../store/actions/language";
import Loader from "../../components/layout/Loader/Loader";
import { useNavigate } from "react-router-dom";
import { singleCompany } from "../../store/actions/company";

const avatars = [
  "/avatar-ti-1.svg",
  "/avatar-ti-2.svg",
  "/avatar-ti-3.svg",
  "/avatar-ti-4.svg",
];
const avatar = avatars[Math.floor(Math.random() * 4)];

const registrationSchema = Yup.object().shape({
  title: Yup.string().required("Title is required"),
  description: Yup.string().required("Description is required"),
  name: Yup.string().required("Name is required"),
  email: Yup.string()
    .email("Wrong email format")
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Email is required"),
  terms: Yup.boolean().oneOf(
    [true],
    "You must accept the pricing policy terms and conditions"
  ),
});

const initialValues = {
  title: "",
  description: "",
  name: "",
  email: "",
  terms: false,
};

const CreateReview = ({ language: propsLanguage }) => {
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const language = useSelector((state) => state.language);
  const company = useSelector((state) => state.company);

  const [rating, setRating] = useState(null);
  const [submited, setSubmited] = useState(false);
  const campaignId = localStorage.getItem("campaignId");
  const [businessId, setBusinessId] = useState("");
  const [isCampaign, setIsCampaign] = useState(false);
  const [loading, setLoading] = useState(false);
  const email = localStorage.getItem("email");
  const slug = businessId;
  const type = "trustbucket";

  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      setLoading(true);
      dispatch(
        writeReview(
          values.title,
          values.description,
          values.name,
          avatar,
          values.email,
          rating,
          campaignId,
          businessId,
          type,
          () => {
            if (isCampaign) {
              navigate(`/${slug}`);
            } else {
              setSubmited(true);
            }
            localStorage.removeItem("campaignId");
            localStorage.removeItem("email");
            setLoading(false);
          },
          () => {
            setLoading(false);
          }
        )
      );
    },
  });

  useEffect(() => {
    const url = new URL(window.location.href);
    const params = new URLSearchParams(url.search);
    const ratingQuery = params.get("rating");
    const name = params.get("name");
    const email = params.get("email");
    const campaignId = params.get("campaignId");
    const businessId = url.pathname.split("/")[2];

    if (ratingQuery) {
      setRating(parseInt(ratingQuery));
    }
    if (campaignId) {
      localStorage.setItem("campaignId", campaignId);
    }
    if (businessId) {
      setBusinessId(businessId);
    }
    if (email) {
      localStorage.setItem("email", email);
    }
    // if (name && email && campaignId) {
    //   formik.values.name = name;
    //   formik.values.email = email;
    //   setIsCampaign(true);
    // }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    dispatch(setLang(propsLanguage));
  }, [dispatch, propsLanguage]);

  useEffect(() => {
    if (slug) {
      dispatch(singleCompany(slug));
    }
  }, [slug]);

  return (
    <>
      {loading && <Loader />}
      <Header />
      <div className="main pt-16 min-h-screen pb-12 text-black">
        <WriteReviewHeader company={company} slug={slug} />
        {!submited ? (
          <form noValidate onSubmit={formik.handleSubmit}>
            <div className="max-w-4xl mt-12 px-4 sm:px-6 mx-auto">
              <div className="mt-12 border max-w-4xl border-gray-200 p-5 rounded-lg bg-white ">
                <div className="text-lg text-black font-bold mb-3">
                  {language["RATE_EXP"]}
                </div>
                <div className="ml-1">
                  <WriteReviewStars
                    handleClick={(rate) => setRating(rate)}
                    placeRating={rating}
                  />
                </div>
                {rating && (
                  <>
                    <div className="mt-3">
                      <label className="block text-sm font-medium mb-1">
                        {language["REVIEW_TITLE"]}
                      </label>
                      <input
                        type="text"
                        placeholder={language["REVIEW_TITLE_PLACEHOLDER"]}
                        {...formik.getFieldProps("title")}
                        className={clsx(
                          "appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-600 focus:ring-2 sm:text-sm",
                          {
                            "is-invalid":
                              formik.touched.title && formik.errors.title,
                          },
                          {
                            "is-valid":
                              formik.touched.title && !formik.errors.title,
                          }
                        )}
                      />
                      {formik.touched.title && formik.errors.title && (
                        <span className="text-red-600 text-xs">
                          {formik.errors.title}
                        </span>
                      )}
                    </div>
                    <div className="mt-3">
                      <label
                        className="block text-sm font-medium mb-1"
                        htmlFor="first-name"
                      >
                        {language["TELL_US"]}
                      </label>
                      <textarea
                        placeholder={language["TELL_US_PLACEHOLDER"]}
                        {...formik.getFieldProps("description")}
                        className={clsx(
                          "appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-600 focus:ring-2 sm:text-sm",
                          {
                            "is-invalid":
                              formik.touched.description &&
                              formik.errors.description,
                          },
                          {
                            "is-valid":
                              formik.touched.description &&
                              !formik.errors.description,
                          }
                        )}
                        rows={5}
                      ></textarea>

                      {formik.touched.description &&
                        formik.errors.description && (
                          <span className="text-red-600 text-xs">
                            {formik.errors.description}
                          </span>
                        )}
                    </div>
                    {!isCampaign && (
                      <div className="mt-3">
                        <div className="block text-sm font-medium mb-1">
                          {language["REVIEWER"]}
                        </div>
                        <div className="flex items-center">
                          <div className="h-20 w-auto object-contain rounded mr-3 overflow-hidden">
                            <img
                              src={avatar}
                              alt="avatar"
                              height={80}
                              width={80}
                            />
                          </div>

                          <div className="w-full">
                            <div className="mb-2">
                              <input
                                type="text"
                                placeholder={language["NAME"]}
                                {...formik.getFieldProps("name")}
                                className={clsx(
                                  "appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-600 focus:ring-2 sm:text-sm",
                                  {
                                    "is-invalid":
                                      formik.touched.name && formik.errors.name,
                                  },
                                  {
                                    "is-valid":
                                      formik.touched.name &&
                                      !formik.errors.name,
                                  }
                                )}
                              />
                              {formik.touched.name && formik.errors.name && (
                                <span className="text-red-600 text-xs">
                                  {formik.errors.name}
                                </span>
                              )}
                            </div>
                            <input
                              type="text"
                              placeholder={language["YOUR_EMAIL"]}
                              {...formik.getFieldProps("email")}
                              className={clsx(
                                "appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-600 focus:ring-2 sm:text-sm",
                                {
                                  "is-invalid":
                                    formik.touched.email && formik.errors.email,
                                },
                                {
                                  "is-valid":
                                    formik.touched.email &&
                                    !formik.errors.email,
                                }
                              )}
                            />
                            {formik.touched.email && formik.errors.email && (
                              <span className="text-red-600 text-xs">
                                {formik.errors.email}
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                    <div className="mb-4">
                      <label className="mt-4 flex items-center">
                        <input
                          type="checkbox"
                          className="form-checkbox mr-2"
                          {...formik.getFieldProps("terms")}
                        />
                        <span>
                          {language["I_ACCEPT"]}{" "}
                          <a
                            className="text-blue-600"
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://trustbucket.io/terms-and-conditions"
                          >
                            {language["TERMS"]}
                          </a>
                        </span>
                      </label>
                      {formik.touched.terms && formik.errors.terms && (
                        <span className="text-red-600 text-xs">
                          {formik.errors.terms}
                        </span>
                      )}
                    </div>
                    <button className="btn px-5 text-base bg-blue-600 text-white transition-colors hover:bg-blue-700">
                      {language["CONFIRM"]}
                    </button>
                  </>
                )}
              </div>
            </div>
          </form>
        ) : (
          <EmailThank email={formik.values.email} />
        )}
      </div>
      <Footer />
    </>
  );
};

// export const getServerSideProps = wrapper.getServerSideProps(
//   () =>
//     async ({ query }) => {
//       const { slug } = query;

//       const { data: companyData } = await axios.get(
//         `rating/trustbucket/${slug}`
//       );

//       if (!companyData) {
//         return {
//           redirect: {
//             destination: "/",
//             permanent: false,
//           },
//         };
//       }

//       let language = "en";
//       if (companyData.reviewsPageLanguage !== "en") {
//         language = companyData.reviewsPageLanguage;
//       }

//       return {
//         props: { slug, companyData, language },
//       };
//     }
// );

export default CreateReview;
