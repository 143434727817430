import axios from "../../config/axios";
import * as actionTypes from "./actionTypes";
import { finishLoading, startLoading } from "./loader";
import { errorsNotification, successNotification } from "./notification";

export const setRatings = (payload) => {
  return {
    type: actionTypes.SET_RATINGS,
    payload,
  };
};

export const setRatingsTotal = (payload) => {
  return {
    type: actionTypes.SET_RATINGS_TOTAL,
    payload,
  };
};

export const setRatingsLastPage = (payload) => {
  return {
    type: actionTypes.SET_RATINGS_LAST_PAGE,
    payload,
  };
};

export const setRatingsStars = (payload) => {
  return {
    type: actionTypes.SET_RATINGS_STARS,
    payload,
  };
};

// export const filterRatings = (
//   companySlug,
//   queryParams,
//   type,
//   rating,
//   callback
// ) => {
//   return (dispatch, getState) => {
//     if (queryParams.pageNumber === 1) {
//       dispatch(startLoading());
//     }
//     axios
//       .post(`rating/list/${companySlug}`, {
//         queryParams,
//         type,
//         rating: rating,
//       })
//       .then(({ data }) => {
//         if (queryParams.pageNumber === 1) {
//           dispatch(setRatings(data?.data));
//         } else {
//           let previousRatings = getState().ratings?.ratings;
//           let newRatings = [...previousRatings, ...data?.data];
//           dispatch(setRatings(newRatings));
//         }
//         dispatch(setRatingsTotal(data?.total));
//         dispatch(setRatingsStars(data?.stars));
//         dispatch(
//           setRatingsLastPage(Math.ceil(data.total / queryParams.pageSize))
//         );
//         callback();
//         dispatch(finishLoading());
//       })
//       .catch((error) => {
//         dispatch(errorsNotification(error?.response?.data?.message));
//         callback();
//         dispatch(finishLoading());
//       });
//   };
// };

export const filterRatings = (businessId, ratings) => {
  return (dispatch) => {
    dispatch(startLoading());
    axios
      .post("/ratings/filter", {
        businessId,
        ratings,
      })
      .then((response) => {
        const ratings = response.data.reviews;
        const total = response.data.total;

        dispatch(setRatings(response.data.reviews));
        dispatch(setRatingsTotal(response.data.total));
        dispatch(
          setRatingsLastPage({
            ratings: ratings,
            total: total,
          })
        );
        dispatch(finishLoading());
      })
      .catch((error) => {
        dispatch(errorsNotification(error?.response?.data?.message));
        dispatch(finishLoading());
      });
  };
};

export const getRatingsList = (businessId) => {
  return (dispatch) => {
    dispatch(startLoading());
    axios
      .get(`/ratings/list?id=${businessId}`)
      .then((response) => {
        const ratings = response.data.reviews;
        const total = response.data.total;

        // Calculate the average rating
        const averageRating =
          ratings.reduce((sum, rating) => sum + rating.rating, 0) / total;

        dispatch(setRatings(response.data.reviews));
        dispatch(setRatingsTotal(response.data.total));
        // dispatch(setUnrepliedTotal(response.data.total));
        dispatch(
          setRatingsLastPage({
            ratings: ratings,
            total: total,
            averageRating: averageRating.toFixed(1),
          })
        );
      })
      .catch((error) => {
        dispatch(finishLoading());
        dispatch(errorsNotification(error?.response?.data?.message));
      });
  };
};

export const writeReview = (
  title,
  description,
  name,
  image,
  email,
  rating,
  campaignId,
  businessId,
  type,
  callback,
  errorCallback
) => {
  return (dispatch, getState) => {
    dispatch(startLoading());

    axios
      .post(`/ratings/create`, {
        title,
        description,
        name,
        image,
        email,
        rating,
        campaignId,
        businessId,
        type,
      })
      .then(({ data }) => {
        callback && callback();
        dispatch(finishLoading());
      })
      .catch((error) => {
        dispatch(errorsNotification(error?.response?.data?.msg));
        errorCallback && errorCallback();
        dispatch(finishLoading());
      });
  };
};

export const confirmReview = (id, callback) => {
  return (dispatch, getState) => {
    dispatch(startLoading());
    axios
      .post(`rating/trustbucket/confirm/${id}`)
      .then(({ data }) => {
        callback && callback();
        dispatch(finishLoading());
        dispatch(successNotification(data?.message));
      })
      .catch((error) => {
        dispatch(errorsNotification(error?.response?.data?.message));
        dispatch(finishLoading());
        callback && callback();
      });
  };
};
