import React from "react";
import { useSelector } from "react-redux";

export default function EmailThank({ email }) {
  const language = useSelector((state) => state.language);

  return (
    <div
      className="flex flex-col items-center"
      style={{ minHeight: 600, paddingTop: 50 }}
    >
      <div className="text-6xl">
        <svg
          width="76"
          height="76"
          viewBox="0 0 76 76"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_568_1175)">
            <path
              d="M70.3 34.0416L35.4666 64.7584L0.474976 34.0416C0.949976 33.25 1.42498 32.3 2.21659 31.6666L31.1916 6.33324C33.5666 4.11662 37.3666 4.11662 39.7416 6.33324L68.7166 31.6666C69.5084 32.3 69.9834 33.25 70.3 34.0416Z"
              fill="#415A6B"
            />
            <path
              d="M63.175 60.1666H7.59998V6.33338C7.59998 2.85 10.45 0 13.9334 0H63.175V60.1666Z"
              fill="#E8EAE9"
            />
            <path
              d="M70.9334 36.575V69.5084C70.9334 73.15 68.0834 76 64.4418 76H6.49177C2.85 76 0 73.15 0 69.5084V36.575C0 35.7834 0.158383 34.8334 0.475 34.0416L35.4666 64.7582L70.4582 34.0416C70.775 34.8334 70.9334 35.7834 70.9334 36.575Z"
              fill="#5589FB"
            />
            <path
              d="M69.35 73.625C68.0834 75.05 66.3416 76 64.4416 76H6.49163C4.43325 76 2.69163 75.05 1.58325 73.625C1.74163 73.3084 2.05825 73.15 2.21663 72.9916L31.1916 47.6583C33.5666 45.4416 37.3666 45.4416 39.7416 47.6583L68.7166 72.9916C68.875 73.15 69.1916 73.4666 69.35 73.625Z"
              fill="#2563EB"
            />
            <path
              d="M13.9333 0H69.6667C73.15 0 76 2.85 76 6.33338V38C76 41.4834 73.15 44.3334 69.6666 44.3334H26.7583C23.275 44.3334 20.425 41.4834 20.425 38V6.33338C20.425 2.85 17.575 0 13.9333 0Z"
              fill="#F3F3F3"
            />
            <path
              d="M33.1301 12.8078C33.0618 12.3841 33.389 12 33.8182 12H55.1818C55.611 12 55.9382 12.3841 55.8699 12.8078L52.7838 31.9744C52.7294 32.3122 52.4378 32.5606 52.0957 32.5606H36.9043C36.5622 32.5606 36.2706 32.3122 36.2162 31.9744L33.1301 12.8078Z"
              fill="url(#paint0_linear_568_1175)"
            />
            <path
              d="M43.8345 17.2517C43.9036 17.0293 44.1024 16.8789 44.3261 16.8789C44.5497 16.8789 44.7477 17.0293 44.8168 17.2517L45.6833 20.0387C45.7089 20.1203 45.7589 20.1919 45.8253 20.2426C45.8924 20.2934 45.9733 20.3209 46.0555 20.3209L48.8627 20.3196C49.0864 20.3196 49.2844 20.47 49.3535 20.6925C49.4225 20.9143 49.3469 21.1574 49.166 21.2948L46.895 23.0165C46.8279 23.0673 46.7779 23.1387 46.7523 23.2205C46.7265 23.3028 46.7273 23.3915 46.7523 23.4732L47.6207 26.2594C47.6898 26.4819 47.6141 26.725 47.4332 26.8624C47.2522 26.9997 47.0068 26.9997 46.8259 26.8624L44.5562 25.1393C44.4892 25.0884 44.409 25.0609 44.3261 25.0609C44.2432 25.0609 44.1628 25.0884 44.0958 25.1393L41.8254 26.8624C41.6445 26.9997 41.3998 26.9997 41.2189 26.8624C41.0373 26.725 40.9616 26.4819 41.0314 26.2594L41.8991 23.4732C41.9247 23.3915 41.9247 23.3028 41.8991 23.2205C41.8741 23.1388 41.8241 23.0673 41.757 23.0165L39.4861 21.2948C39.3052 21.1574 39.2289 20.9143 39.2978 20.6925C39.3676 20.47 39.5657 20.3196 39.7894 20.3196L42.5958 20.3209C42.6787 20.3209 42.7596 20.2934 42.8261 20.2426C42.8932 20.1919 42.9431 20.1203 42.9681 20.0387L43.8345 17.2517Z"
              fill="white"
            />
          </g>
          <defs>
            <linearGradient
              id="paint0_linear_568_1175"
              x1="44.5"
              y1="12"
              x2="44.5"
              y2="32.5606"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#3B82F6" />
              <stop offset="0.505208" stopColor="#2563EB" />
              <stop offset="1" stopColor="#1D4ED8" />
            </linearGradient>
            <clipPath id="clip0_568_1175">
              <rect width="76" height="76" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </div>
      <div className="font-bold text-4xl my-8" style={{ color: "#253342" }}>
        {language["THANK_YOU"]}
      </div>
      <div className="text-lg mb-6" style={{ color: "#33475B" }}>
        {language["WE_SENT"]} <strong>{email}</strong>
      </div>
      <div
        className="text-lg max-w-lg text-center"
        style={{ color: "#33475B", lineHeight: "34px" }}
      >
        {language["THANK_YOU_DESC"]}
      </div>
    </div>
  );
}
