import { compose } from "redux";
// import { applyMiddleware } from "redux";
import { configureStore } from "@reduxjs/toolkit";
import rootReducer from "./reducers";
import { thunk } from "redux-thunk";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// initial state here
const initialState = {};

// middleware
const middleware = [thunk];

// creating store
const store = configureStore({
  reducer: rootReducer,
  preloadedState: initialState
});

export default store;
