import FreshaStars from "./Stars/FreshaStars";
import RatingStars from "./Stars/RatingStars";
import RecoStars from "./Stars/RecoStars";
import TrustPilotStars from "./Stars/TrustPilotStars";
import React from "react";
import Stars from "react-stars";

const PlatformStars = ({ type, rating }) => {
  if (type === "recose") {
    return <RecoStars rating={rating} />;
  } else if (type === "booking" || type === "trustbucket") {
    return <RatingStars rating={rating} />;
  } else if (type === "fresha" || type === "bokadirekt") {
    return <FreshaStars rating={rating} />;
  } else if (type === "trustpilot") {
    return <TrustPilotStars rating={rating} />;
  } else if (type === "hitta") {
    return (
      <div className="mb-2">
        <Stars
          count={5}
          edit={false}
          value={rating}
          size={16}
          color2={"#f1bf00"}
        />
      </div>
    );
  } else {
    return (
      <div style={{ marginTop: -4 }}>
        <Stars
          count={5}
          edit={false}
          value={rating}
          size={16}
          color2={"#f1bf00"}
        />
      </div>
    );
  }
};

export default PlatformStars;
