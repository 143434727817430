import React from "react";

const Loader = () => {
  return (
    <div className="loader">
      <div className="loader__icons-wrapper">
        <div className="loader__circle"></div>
        <img
          objectFit="contain"
          src="/mini-logo.svg"
          alt="Logo"
          height={60}
          width={60}
        />
      </div>
    </div>
  );
};

export default Loader;
