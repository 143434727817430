import React from "react";
import { useSelector } from "react-redux";

const Header = () => {
  const lang = useSelector((state) => state.language);
  return (
    <header className="header bg-gray-900 fixed w-full z-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6">
        <div className="flex justify-between items-center py-4 sm:py-3 md:justify-start md:space-x-10">
          <div className="flex justify-start lg:w-0 lg:flex-1">
            <a href="https://trustbucket.io">
              <span className="sr-only">Trustbucket</span>
              <div className="h-auto w-40 sm:h-10">
                <img
                  src="/logo-white.svg"
                  alt="Trustbucket AB"
                  width={160}
                  height={40}
                />
              </div>
            </a>
          </div>

          <nav className="flex space-x-10"></nav>
          <div className="flex items-center justify-end md:flex-1 lg:w-0">
            <a
              className="text-white mr-2 md:block hidden"
              href="https://trustbucket.io/learn"
            >
              {lang["BLOGS"]}
            </a>
            <a
              href="https://trustbucket.io"
              className="ml-2 whitespace-nowrap cursor-pointer inline-flex items-center justify-center px-4 py-2 rounded-md text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 border border-blue-600 hover:border-blue-700"
            >
              {lang["FOR_BUSINESS"]}
            </a>
          </div>
        </div>
      </div>
      <div className="header__filling-line"></div>
    </header>
  );
};

export default Header;
