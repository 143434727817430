import { combineReducers } from "redux";

// reducers
import loader from "./loader";
// import auth from "./auth";
import notification from "./notification";
// import google from "./google";
// import platform from "./platform";
import ratings from "./ratings";
// import stats from "./stats";
// import emailTemplates from "./emailTemplates";
// import campaign from "./campaign";
// import logs from "./logs";
import company from "./company";
import language from "./language";

const rootReducer = combineReducers({
  loader,
  ratings,
  language,
  company,
  notification,
});

export default rootReducer;
