import React, { useState } from "react";
import Footer from "../components/layout/Footer/Footer";
import Header from "../components/layout/Header/Header";
import CompanyHeader from "../components/pages/SingleCompany/CompanyHeader";
import CompanyAbout from "../components/pages/SingleCompany/CompanyAbout";
import WriteReviewStars from "../components/pages/SingleCompany/WriteReviewStars";
import CompanyReviews from "../components/pages/SingleCompany/CompanyReviews";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { setLang } from "../store/actions/language.js";
import { generateReviewsSchema } from "../utils/generateReviewSchema.js";
import { useNavigate } from "react-router-dom";
import { getRatingsList } from "../store/actions/ratings.js";
import { singleCompany } from "../store/actions/company.js";

const initialQueryParams = {
  pageNumber: 1,
  pageSize: 10,
  sortField: "date",
  sortOrder: "desc",
};

const Company = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [businessId, setBusinessId] = useState("");
  const [companyName, setCompanyName] = useState("");
  const language = useSelector((state) => state.language);
  const ratings = useSelector((state) => state.ratings);
  const company = useSelector((state) => state.company);
  const slug = businessId;

  useEffect(() => {
    const url = new URL(window.location.href);
    const params = new URLSearchParams(url.search);
    const businessId = url.pathname.split("/")[1];
    const name = params.get("name");

    if (businessId) {
      setBusinessId(businessId);
    }
    if (name) {
      setCompanyName(name);
    }
    // eslint-disable-next-line
  }, []);

  // useEffect(() => {
  //   dispatch(setLang(propsLanguage));
  // }, [dispatch, propsLanguage]);

  useEffect(() => {
    if (slug) {
      dispatch(getRatingsList(slug));
    }
  }, [slug]);

  useEffect(() => {
    if (slug) {
      dispatch(singleCompany(slug));
    }
  }, [slug]);

  return (
    <>
      <>
        <meta charSet="utf-8" />
        <title>{`${companyName} Reviews - Trustbucket`}</title>
        <meta
          name="description"
          content={`Read ${companyName} Reviews on Trustbucket`}
        />
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={generateReviewsSchema({
            slug,
            companyName,
          })}
          data-n-head="ssr"
          data-hid="pdp-schema"
        >
          {}
        </script>
      </>
      <Header />
      <div className="main pt-16 pb-12 text-black min-h-screen">
        <CompanyHeader company={company} ratings={ratings} />
        <div className="bg-white p-6 mt-5 sm:hidden block">
          <div className="bg-blue-600 rounded w-full p-4 block text-white text-center font-semibold">
            <a href={`/write-review/${slug}`}>{language["WRITE_REVIEW"]}</a>
          </div>
        </div>
        <div className="max-w-7xl mx-auto px-4 mt-10 sm:mt-14 sm:px-6 bg-blue flex flex-col md:flex-row justify-between">
          <div className="w-full md:w-8/12">
            <div className="border border-gray-200 rounded-lg bg-white p-5 hidden sm:flex flex-col sm:flex-row sm:items-center justify-between mb-4">
              <div className="flex items-center">
                <div className="w-10 h-10 flex items-center justify-center bg-gray-100 rounded-full mr-4">
                  <div className="w-5 h-5">
                    <img
                      src="/trustbucket-avatar.svg"
                      alt="avatar"
                      width={20}
                      height={20}
                    />
                  </div>
                </div>
                <div className="text-blue-600 sm:text-lg">
                  <a href={`/write-review/${slug}`}>
                    {language["WRITE_REVIEW"]}
                  </a>
                </div>
              </div>
              <div className="sm:mt-0 mt-2 sm:block flex justify-end">
                <WriteReviewStars
                  handleClick={(ratingVal) =>
                    navigate(`write-review/${slug}?rating=${ratingVal}`)
                  }
                />
              </div>
            </div>

            <CompanyReviews company={company} slug={slug} ratings={ratings} />
          </div>
          <CompanyAbout company={company} />
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Company;
