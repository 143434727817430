import React from "react";

const ProgressBar = ({ percent }) => {
  return (
    <div className="bg-gray-200 h-4 rounded w-28 sm:w-20 md:w-24">
      <div
        className="h-full bg-blue-600 rounded"
        style={{ width: `${percent}%` }}
      ></div>
    </div>
  );
};

export default ProgressBar;
