import * as actionTypes from "../actions/actionTypes";

const initialState = {
  ratings: [],
  total: 0,
  lastPage: 10,
  stars: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_RATINGS:
      return {
        ...state,
        ratings: action.payload,
      };
    case actionTypes.SET_RATINGS_TOTAL:
      return {
        ...state,
        total: action.payload,
      };
    case actionTypes.SET_RATINGS_LAST_PAGE:
      return {
        ...state,
        lastPage: action.payload,
      };
    case actionTypes.SET_RATINGS_STARS:
      return {
        ...state,
        stars: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
