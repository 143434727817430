import React from "react";
import dayjs from "dayjs";
import { typeObject } from "../../../utils/getObjectByType";
import parse from "html-react-parser";
import PlatformStars from "./components/PlatformStars";

const SingleReview = ({ data, company }) => {
  return (
    <div className="p-6 border border-gray-200 rounded-lg bg-white mb-4">
      <div className="flex items-center justify-between pb-4 border-b border-gray-200">
        <div className="flex items-center">
          <div className="w-11 h-11 rounded-full mr-4">
            {data?.image ? (
              <div
                className="w-full h-full rounded-full overflow-hidden"
                style={{
                  background: `url('${data?.image}') no-repeat center center / cover`,
                }}
              ></div>
            ) : (
              <div className="w-full h-full text-white bg-blue-500 rounded-full flex items-center font-semibold justify-center text-xl">
                {data?.name?.substring(0, 1)}
              </div>
            )}
          </div>

          <div>
            <div className="text-sm text-black font-bold mb-1.5 capitalize">
              {data.name}
            </div>
            <div className="text-sm text-gray-700">
              {data?.createdAt &&
                dayjs(data?.createdAt).format("MMMM DD, YYYY")}
            </div>
          </div>
        </div>
        <div>
          <div className="h-8 w-14 relative">
            <img
              src={typeObject[data.type]?.image}
              alt="Platform"
              height={36}
              width="100%"
              layout="fill"
              // objectFit="contain"
            />
          </div>
        </div>
      </div>
      <div className="flex items-center mt-3.5">
        <PlatformStars rating={data.rating} type={data?.type} />
        {data?.verified && (
          <div className="flex items-center ml-4">
            <img src="/verified.svg" alt="Verified" width={20} height={20} />
            <div className="text-xs text-green-600 ml-1.5">Verified</div>
          </div>
        )}
      </div>
      {data?.title && (
        <div className="font-bold text-black mb-2 text-lg mt-3">
          {data.title}
        </div>
      )}
      <div className="">{parse(data?.description || "")}</div>

      {data?.reply && (
        <div className="mt-4">
          <div
            className="border-l-4 border-blue-600 pl-2 pr-6 py-4 flex"
            style={{ background: "#F2F2F5" }}
          >
            <div className="w-4 mt-1.5">
              <svg
                width="16"
                height="12"
                viewBox="0 0 16 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.955 9.09453C3.547 9.00353 -2.86102e-06 6.80653 -2.86102e-06 1.12453C0.00105667 0.842155 0.114108 0.571737 0.31434 0.372631C0.514571 0.173525 0.785622 0.0619974 1.068 0.0625296C1.26037 0.0624485 1.44921 0.114233 1.61463 0.212431C1.78006 0.310629 1.91595 0.451609 2.008 0.620529C3.093 2.61853 4.835 3.43753 6.956 3.43753C7.31 3.43753 7.646 3.41353 7.955 3.37353V1.74953C7.955 1.33653 8.195 0.96253 8.57 0.78753C8.94 0.61553 9.377 0.667529 9.697 0.925529L15.107 5.30253C15.2296 5.40208 15.3284 5.52773 15.3962 5.67033C15.4641 5.81292 15.4992 5.96886 15.4991 6.12677C15.499 6.28468 15.4637 6.44057 15.3957 6.58308C15.3277 6.72559 15.2287 6.85113 15.106 6.95053L9.696 11.3265C9.377 11.5825 8.94 11.6365 8.57 11.4635C8.195 11.2885 7.955 10.9155 7.955 10.5005V9.09553V9.09453ZM8.244 7.59753C8.401 7.59753 8.544 7.59353 8.664 7.58753C8.76572 7.5821 8.86748 7.59744 8.96307 7.63263C9.05867 7.66782 9.14609 7.72211 9.22002 7.7922C9.29394 7.86228 9.35281 7.94669 9.39304 8.04028C9.43326 8.13386 9.45401 8.23466 9.454 8.33653V9.59053L13.74 6.12453L9.455 2.65853V4.00553C9.45501 4.17683 9.39638 4.34298 9.28886 4.47633C9.18134 4.60968 9.0314 4.70221 8.864 4.73853C8.23683 4.87216 7.59725 4.93887 6.956 4.93753C4.856 4.93753 2.987 4.25653 1.619 2.68553C2.202 6.26653 4.874 7.59753 8.244 7.59753Z"
                  fill="#C5C5D6"
                />
              </svg>
            </div>
            <div className="ml-4">
              <div className="flex items-center justify-between mb-4">
                <div className="text-sm font-bold text-black">
                  Reply from {company?.company?.businessName}
                </div>
              </div>
              <div className="text-sm text-gray-600">{parse(data.reply)}</div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SingleReview;
